<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Tipo <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.tipo"
            :options="form_options.tipo"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
      >
        <template #label>
          Depreciação Anual <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-input-group append="%">
            <KTInputNumeric
              v-model="form.percentual_depreciacao"
              :precision="2"
              :min="0"
              :max="100"
              placeholder="Digite o percentual de depreciação anual"
              autocomplete="off"
              :required="!finalizado"
              :disabled="finalizado"
            />
          </b-input-group>
        </template>
      </b-form-group>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipDepreciacaoForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_investimento_depreciacao: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-dollar-sign",
        title: "Depreciação",
        description: `A perda de valor de um bem no decorrer do tempo
          é chamada de depreciação. Essa desvalorização pode ser decorrente
          do desgaste natural, do uso ou ainda de sua obsolescência. Alguns
          exemplos de bens que sofrem perda de valor são as infraestruturas,
          máquinas, equipamentos e veículos. É a Receita Federal quem
          determina qual a vida útil estimada de um bem e, com base nela,
          sua taxa anual de depreciação. Os valores cadastrados aqui na PAEF
          seguem essa determinação, portanto não é necessário altera-los. Se
          necessário você pode cadastrar outra categoria.`
      },
      form: {},
      form_options: {
        tipo: [
          {
            value: 1,
            text: "Terrenos - Investimento Fixo"
          },
          {
            value: 2,
            text: "Edificações - Investimento Fixo"
          },
          {
            value: 3,
            text: "Instalações - Investimento Fixo"
          },
          {
            value: 4,
            text: "Máquinas e Equipamentos - Investimento Fixo"
          },
          {
            value: 5,
            text: "Móveis e Utensílios - Investimento Fixo"
          },
          {
            value: 6,
            text: "Veículos - Investimento Fixo"
          },
          {
            value: 7,
            text: "Computadores e Periféricos - Investimento Fixo"
          },
          {
            value: 8,
            text: "Veículos de Mercadorias - Investimento Fixo"
          },
          {
            value: 9,
            text: "Semoventes - Investimento Fixo"
          },
          {
            value: 10,
            text: "Serviço de Terceiros Pessoa Física - Investimento Pré-operacional"
          },
          {
            value: 11,
            text: "Serviço de Terceiros Pessoa Jurídica - Investimento Pré-operacional"
          },
          {
            value: 12,
            text: "Insumos iniciais - Investimento Pré-operacional"
          },
          {
            value: 13,
            text: "Outras Contrapartidas em Investimento Fixo (Bens Usados)"
          }
        ]
      },
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/depreciacao`;
    },
    manual() {
      return {
        session: "depreciacao",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_investimento_depreciacao;
    },
    parentRoute() {
      return {
        name: "pip_depreciacao_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_investimento_depreciacao)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.form = res[R_GETDATA].depreciacao ?? {};
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_investimento_depreciacao, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
